import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as users from '../../../api/authApi/users';
import * as enterprise from '../../../api/authApi/enterprise';
import { navigate } from '../../../lib/utils/navigation';
import * as groups from '../../../api/authApi/groups';
import * as generics from '../../../api/appApi/generics';
import { notificationActions } from '../notification';
import * as actions from './actions';
import * as selectors from './selectors';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import { authActions, authSelectors } from '../auth';

export function* getListUsers(payload) {
	console.log(payload);
	const refresh = yield select(selectors.getRefresh);

	if (!refresh) {
		yield put(apiActions.apiStart());
	}

	let query = '';

	if (payload.query !== '') {
		query = `?${payload.query}`;
	}

	yield put(apiActions.setQueryFilter(payload.query));

	try {
		const response = yield call(users.get, query);

		yield put(actions.setListUsers(response.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar usuários.',
				'error',
			),
		);
	}

	yield put(apiActions.apiEnd());
	yield put(actions.setRefresh(false));
}

export function* getUserById(payload) {
	yield put(apiActions.apiStart());

	const id = payload.user;

	try {
		const response = yield call(users.getUser, id);

		yield put(actions.setUser(response.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar usuário.',
				'error',
			),
		);
	}

	yield put(apiActions.apiEnd());
}

export function* addUser(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.user;

	try {
		const response = yield call(users.addUser, data);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Usuário cadastrado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/usuarios');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao cadastrar usuário.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getUserPermission(payload) {
	yield put(apiActions.apiStart());

	const { user } = payload;
	console.log('Dados', user);

	try {
		let companys = yield call(enterprise.getCompany);
		let userPermission = yield call(users.getUserPermission, user);

		const permission = [
			{ empresa: companys.data },
			{ permission: userPermission.data }
		];
		console.log('userPermission', permission);

		yield put(actions.setUserPermission(permission));
	} catch (error) {
		console.log('erro', error);
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar permissões do usuário.',
				'error',
			),
		);
	}

	yield put(apiActions.apiEnd());
}


export function* activeDesactiveUser(payload) {
	const { user } = payload;

	try {
		const data = {
			ativo: !user.ativo,
		};

		const response = yield call(users.activeDesactiveUser, user.id, data);

		if (response) {
			const query = yield select(apiSelectors.getQuery);

			yield put(actions.setRefresh(true));

			yield put(actions.getListUsers(query));
		}
	} catch (error) {
		console.log('erro', error);
		yield put(
			notificationActions.addNotification(
				'Erro ao mudar status do usuário.',
				'error',
			),
		);
	}
}

export function* updateUserPermission(payload) {
	yield put(apiActions.apiSubmitStart());

	const { data } = payload;
	console.log('Dados', data);

	try {
		const response = yield call(users.updateUserPermission, data);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Permissões do usuário atualizadas com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/usuarios');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao atualizar permissões do usuário.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}


export function* changePassword(payload) {
	const { password, id } = payload.password;

	console.log('password', password, 'id', id);

	try {
		// Passando o `id` no URL e o `password` no corpo da requisição
		const response = yield call(users.changePassword, { password }, id);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Senha alterada com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/perfil');
			}, 1200);
		}
	} catch (error) {
		console.log('erro', error);
		yield put(
			notificationActions.addNotification(
				'Erro ao alterar senha.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* editUserProfile(payload) {
	yield put(apiActions.apiSubmitStart());

	const user = yield select(authSelectors.getUser);

	const data = {
		username: payload.data.username,
		email: payload.data.email,
		nome: payload.data.nome,
		nome: payload.data.nome,
	};

	const imgProfile = payload.data.file
	try {
		const response = yield call(users.editUser, data, user.id);
		const responseImgProfile = yield call(generics.setImgProfile, imgProfile, { id_user: user.id })

		response.data.img = responseImgProfile.data.data[0].url

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Pefil alterarada com sucesso!',
					'success',
				),
			);

			localStorage.removeItem('user');
			localStorage.setItem('user', JSON.stringify(response.data));

			yield put(authActions.setUser(response.data));
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao alterar perfil.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* editUser(payload) {
	yield put(apiActions.apiSubmitStart());

	const data = payload.user;

	const { id } = payload;

	try {
		const response = yield call(users.editUser, data, id);
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Usuário editado com sucesso!',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/usuarios');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao editar usuário.',
				'error',
			),
		);
	}

	yield put(apiActions.apiSubmitEnd());
}

export function* getUsersFilterbyGroupLogged(payload) {
	const query = `?${payload.query}`;

	try {
		const response = yield call(users.getCollaborator, query);

		const user = yield select(authSelectors.getUser);

		let data = response.data.data.filter(item => {
			return item.extra_id !== null;
		});

		data = data.map(_item => {
			const item = { ..._item };
			item.name = `${item.nome} ${item.nome}`;
			item.id = item.extra_id;
			return item;
		});

		const values = data.filter(item => {
			return item.id === user.extra_id;
		});

		if (values.length > 0) {
			yield put(actions.setIsRepresentative(true));
		}

		yield put(actions.setCollaborators(values.length > 0 ? values : data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar usuários.',
				'error',
			),
		);
	}
}

export function* getCoordinators(payload) {
	const query = `?${payload.query}`;

	try {
		const response = yield call(users.getCollaborator, query);

		response.data.data = response.data.data.map(_item => {
			const item = { ..._item };
			item.name = `${item.nome} ${item.nome}`;
			return item;
		});

		yield put(actions.setCoordinators(response.data.data));
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar coordenadores.',
				'error',
			),
		);
	}
}

export default function* watchUsers() {
	yield takeLatest(types.GET_LIST_USERS, getListUsers);
	yield takeLatest(types.ADD_USER, addUser);
	yield takeLatest(types.GET_USER, getUserById);
	yield takeLatest(types.GET_USER_PERMISSION, getUserPermission);
	yield takeLatest(types.ACTIVE_DESACTIVE_USER, activeDesactiveUser);
	yield takeLatest(types.UPDATE_USER_PERMISSION, updateUserPermission);
	yield takeLatest(types.CHANGE_PASSWORD, changePassword);
	yield takeLatest(types.EDIT_USER_PROFILE, editUserProfile);
	yield takeLatest(types.EDIT_USER, editUser);
	yield takeLatest(
		types.GET_USERS_FILTER_BY_LOGGED,
		getUsersFilterbyGroupLogged,
	);
	yield takeLatest(types.GET_COORDINATORS, getCoordinators);
}
