import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
// import 'react-tabulator/lib/styles.css'; // default theme
// import 'react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css'; // use Theme(s)
import '../../styles/table/table_custom.min.css';
import { useLocation } from 'react-router-dom';
import { ReactTabulator } from 'react-tabulator';
import { Page } from '../../components/Utils/Page';
import { getAproveTable } from './TableData';

const REACT_APP_BASE_APP = process.env.REACT_APP_BASE_APP;
const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Aug',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const numMonths = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const ReturnMonths = [
	'janeiro',
	'fevereiro',
	'marco',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro',
];

let date = new Date();
let currentMonth = date.getMonth();
let currentYear = date.getFullYear();
let results = [];
let NumResults = [];
let Mresults = [];
let valorCell = '';

for (let i = 0; i < 7; i++) {
	let nextMonth = (currentMonth + i) % 12;
	let nextYear = currentYear + Math.floor((currentMonth + i) / 12);
	results.push({
		month: months[nextMonth],
		year: nextYear.toString().slice(-2),
	});
	Mresults.push({
		month: ReturnMonths[nextMonth],
		year: nextYear.toString().slice(-2),
	});
	NumResults.push({
		month: numMonths[nextMonth],
		year: nextYear.toString(),
	});
}

const moneyFormatter = function(cell, formatterParams) {
	const value = parseFloat(cell.getValue());
	const decimal = formatterParams.decimal || '.';
	const thousand = formatterParams.thousand || ',';
	const symbol = formatterParams.symbol || '';

	if (value == '' || value == '0' || value == undefined || value == null) {
		return '';
	}

	const stringValue = value.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal);
};

function ModalInvite(array, novoCampo) {
	const index = array.findIndex(campo => campo.id === novoCampo.id);
	if (index !== -1) {
		array[index] = novoCampo;
		console.log('Array: ' + JSON.stringify(ArrayModal));
	} else {
		array.push(novoCampo);
		// console.log('Array: ' + JSON.stringify(ArrayModal));
	}
}

export const refreshAproveData = (setStateFunction) => {
	const month = NumResults[0].month;
		const year = NumResults[0].year;

		getAproveTable(month, year)
			.then(response => {
				setStateFunction({ Mdata: response });
			})
			.catch(error => {
				console.log(error);
			});
}

// parametros das colunas da tabela

const dataTableColumns = [
	{
		title: 'Obra',
		field: 'nomeobra',
		width: 400,
		hozAlign: 'left',
		headerFilter: 'input',
	},
	{
		title: 'Descrição',
		field: 'nmorcam',
		width: 400,
		hozAlign: 'left',
		headerFilter: 'input',
	},
	{
		title: 'Fornecedor',
		field: 'nomefornecedor',
		width: 1000,
		hozAlign: 'left',
		headerFilter: 'input',
	},
	{
		title: 'Vencimento',
		field: 'vencimento',
		width: 200,
		hozAlign: 'right',
		headerFilter: 'input',
	},
	{
		title: 'Valor',
		field: 'valor',
		width: 180,
		formatter: 'money',
		formatterParams: {
			decimal: ',',
			thousand: '.',
			symbol: 'R$ ',
		},
		hozAlign: 'right',
		bottomCalc: 'sum',
		bottomCalcParams: { precision: 2 },
		bottomCalcFormatter: function(cell, formatterParams) {
			const valorCell = cell.getValue();

			let formattedValue = valorCell;
			if (valorCell >= 0) {
				cell.getElement().style.color = '#108A20';
				formattedValue = moneyFormatter(cell, formatterParams);
			} else if (valorCell < 0) {
				cell.getElement().style.color = '#8A1010';
				formattedValue = moneyFormatter(cell, formatterParams);
			} else if (valorCell === 'undefined') {
				cell.getElement().style.color = 'blue';
				formattedValue = moneyFormatter(cell, formatterParams);
			}
			return formattedValue;
		},
		bottomCalcFormatterParams: {
			decimal: ',',
			thousand: '.',
			symbol: 'R$ ',
		},
	},
	{
		title: 'Situação',
		field: 'aprovacao',
		width: 250,
		headerSort: false,
		formatter: 'tickCross',
		hozAlign: 'center',
		hozAlign: 'center',
		editor: true,
		headerFilter: 'tickCross',
		headerFilterParams: { tristate: true },
	},
];

const tableStyle = {
	background: '#f5f5f5',
	marginBottom: '5px',
	color: '#000000',
	fontSize: '14px',
	fontWeight: 'bold',
	border: '2px solid #ddd',
	borderRadius: '8px',
	width: '100%',
	tableLayout: 'fixed',
};

const rowStyle = {
	background: '#f5f5f5',
	color: '#000000',
	fontSize: '14px',
	textAlign: 'left',
	border: 'none',
};

const ArrayModal = [];

class ConfirmationPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			// originalData: [],
		};
	}

	componentDidMount() {
		refreshAproveData(this.setState.bind(this));
	}

	render() {
		const dataTableConfig = {
			height: '100%',
			reactiveData: true,
			persistentLayout: true,
			groupStartOpen: [true, false],
			groupClosedShowCalcs: true,
			columnCalcs: 'group',
			groupBy: ['nomeobra', 'nmorcam'],
			groupHeader: function(value, count, data, group) {
				return (
					"<span style='color:#0A264B;'>" +
					value +
					' </span>' +
					"<span style='color:#0A264B; margin-left:10px;'>( " +
					count +
					' items )</span>'
				);
			},
		};

		const handleSave = (
			Mdata,
			props,
			ArrayModal,
			Cid,
			aprovacao_pgto,
			contador,
		) => {
			// cria um objeto com as informações a serem salvas na API
			const saveData = {
				id: Cid,
				aprovacao_pgto: ArrayModal.aprovacao_pgto,
			};

			// configura as opções da requisição
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(saveData),
			};

			// envia a requisição para a API AdonisJS
			fetch(`${REACT_APP_BASE_APP}ctaspagar`, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('Erro ao enviar dados à API');
					}
					return response.json();
				})
				.then(response => {
					if (!response.command) {
						throw new Error(
							'Os dados inseridos estão incorretos. Verifique as informações e tente novamente.',
						);
					}
					console.log('response: ' + JSON.stringify(response));
					console.log(
						'requestOptions: ' + JSON.stringify(requestOptions),
					);
				})
				.catch(error => {
					console.error(error);
					const errorMessage = `${error.message}`;
				});
		};

		const buttonStyle = {
			margin: '5px',
			transition: 'all 0.6s ease-in-out',
			float: 'right',
			marginRight: '20px',
		};

		return (
			<div className="parent">
				<div className="div1">
					{/* tabela Dados */}

					<ReactTabulator
						style={tableStyle}
						rowStyle={rowStyle}
						columns={dataTableColumns}
						data={this.state.Mdata}
						options={dataTableConfig}
						events={{
							tableBuilt: () => {
								const { Mdata } = this.state;
								if (Array.isArray(Mdata)) {
									for (let i = 0; i < Mdata.length; i++) {
										const currentElement = Mdata[i];
										const rowData = currentElement;
										const Cid = rowData['id'];
										const valorCell =
											rowData['aprovacao_pgto'];
										ModalInvite(ArrayModal, {
											id: Cid,
											aprovacao_pgto: valorCell,
										});
									}
								}
							},
							cellEdited: cell => {
								valorCell = cell.getValue();
								const rowData = cell.getRow().getData();
								const Cid = rowData['id'];
								ModalInvite(ArrayModal, {
									id: Cid,
									aprovacao_pgto: valorCell,
								});
							},
						}}
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}>
						<Button
							onClick={() => {
								const { data, Mdata } = this.state;
								for (let i = 0; i < Mdata.length; i++) {
									const currentElement = Mdata[i];
									currentElement.aprovacao = true;
									const contador = i + 1;
									ModalInvite(ArrayModal, {
										id: currentElement.id,
										aprovacao_pgto: true,
									});
								}
							}}
							variant="outline-success"
							style={buttonStyle}>
							Aprovar Todos
						</Button>
						<Button
							onClick={() => {
								for (let i = 0; i < ArrayModal.length; i++) {
									console.log(
										'Tentativa de Envio: ' + (i + 1),
									);
									console.log(ArrayModal);
									const currentElement = ArrayModal[i];
									const contador = i + 1;
									handleSave(
										this.state.Mdata,
										this.props,
										currentElement,
										currentElement.id,
										currentElement.aprovacao_pgto,
										contador,
									);
								}
							}}
							variant="outline-success"
							style={buttonStyle}>
							Salvar
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default ConfirmationPage;
