import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
// import 'react-tabulator/lib/styles.css'; // default theme
// import 'react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css'; // use Theme(s)
import '../../styles/table/table_custom.min.css';
import { useLocation } from 'react-router-dom';
import { ReactTabulator } from 'react-tabulator';
import { Page } from '../../components/Utils/Page';
import { getDataModal } from './TableData';

const REACT_APP_BASE_APP = process.env.REACT_APP_BASE_APP;
const months = [
	'Jan',
	'Fev',
	'Mar',
	'Abr',
	'Mai',
	'Jun',
	'Jul',
	'Aug',
	'Set',
	'Out',
	'Nov',
	'Dez',
];

const numMonths = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const ReturnMonths = [
	'janeiro',
	'fevereiro',
	'marco',
	'abril',
	'maio',
	'junho',
	'julho',
	'agosto',
	'setembro',
	'outubro',
	'novembro',
	'dezembro',
];

let date = new Date();
let currentMonth = date.getMonth();
let currentYear = date.getFullYear();
let results = [];
let NumResults = [];
let Mresults = [];
let valorCell = '';

for (let i = 0; i < 7; i++) {
	let nextMonth = (currentMonth + i) % 12;
	let nextYear = currentYear + Math.floor((currentMonth + i) / 12);
	results.push({
		month: months[nextMonth],
		year: nextYear.toString().slice(-2),
	});
	Mresults.push({
		month: ReturnMonths[nextMonth],
		year: nextYear.toString().slice(-2),
	});
	NumResults.push({
		month: numMonths[nextMonth],
		year: nextYear.toString(),
	});
}

const moneyFormatter = function(cell, formatterParams) {
	const value = parseFloat(cell.getValue());
	const decimal = formatterParams.decimal || '.';
	const thousand = formatterParams.thousand || ',';
	const symbol = formatterParams.symbol || '';

	if (value == '' || value == '0' || value == undefined || value == null) {
		return '';
	}

	const stringValue = value.toFixed(2).replace('.', decimal);
	const parts = stringValue.split(decimal);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand);

	return symbol + parts.join(decimal);
};

function ModalInvite(array, novoCampo) {
	const index = array.findIndex(campo => campo.id === novoCampo.id);
	if (index !== -1) {
		array[index] = novoCampo;
		console.log('Array: ' + JSON.stringify(ArrayModal));
	} else {
		array.push(novoCampo);
		console.log('Array: ' + JSON.stringify(ArrayModal));
	}
}

// parametros das colunas da tabela

const dataTableColumns = [
	{
		title: 'Descrição',
		field: 'descricao',
		width: 350,
		hozAlign: 'left',
		headerFilter: 'input',
		frozen: true,
	},
	{
		title: 'Codigo Orçamento',
		field: 'codorcam',
		width: 250,
		hozAlign: 'left',
		headerFilter: 'input',
	},
	{
		title: 'Fornecedor',
		field: 'nomefornecedor',
		width: 400,
		hozAlign: 'left',
		headerFilter: 'input',
	},
	{
		title: 'Vencimento',
		field: 'vencimento',
		width: 250,
		hozAlign: 'left',
		headerFilter: 'input',
	},
	{
		title: 'Valor',
		field: 'valor',
		width: 250,
		formatter: 'money',
		formatterParams: {
			decimal: ',',
			thousand: '.',
			symbol: 'R$ ',
		},
		hozAlign: 'right',
		bottomCalc: 'sum',
		bottomCalcParams: { precision: 2 },
		bottomCalcFormatter: function(cell, formatterParams) {
			const valorCell = cell.getValue();

			let formattedValue = valorCell;
			if (valorCell >= 0) {
				cell.getElement().style.color = '#108A20';
				formattedValue = moneyFormatter(cell, formatterParams);
			} else if (valorCell < 0) {
				cell.getElement().style.color = '#8A1010';
				formattedValue = moneyFormatter(cell, formatterParams);
			} else if (valorCell === 'undefined') {
				cell.getElement().style.color = 'blue';
				formattedValue = moneyFormatter(cell, formatterParams);
			}
			return formattedValue;
		},
		bottomCalcFormatterParams: {
			decimal: ',',
			thousand: '.',
			symbol: 'R$ ',
		},
	},
	{
		title: 'Situação',
		field: 'aprovacao',
		width: 250,
		headerSort: false,
		formatter: 'tickCross',
		hozAlign: 'center',
		hozAlign: 'center',
		editor: true,
		headerFilter: 'tickCross',
		headerFilterParams: { tristate: true },
	},
];

const tableStyle = {
	background: '#f5f5f5',
	marginBottom: '5px',
	color: '#000000',
	fontSize: '14px',
	fontWeight: 'bold',
	border: '2px solid #ddd',
	borderRadius: '8px',
	width: '100%',
	tableLayout: 'fixed',
};

const rowStyle = {
	background: '#f5f5f5',
	color: '#000000',
	fontSize: '14px',
	textAlign: 'left',
	border: 'none',
};

const ArrayModal = [];

class TableAprove extends React.Component {
	constructor(props) {
		super(props);

		// Estado inicial com dados da tabela
		this.state = {
			data: [],
			originalData: [],
		};
	}

	componentDidMount() {
		const searchParams = new URLSearchParams(this.props.location.search);
		const dataParam = searchParams.get('data');
		const data = JSON.parse(decodeURIComponent(dataParam));
		this.setState({
			data: data,
			originalData: data,
		});

		// Obtém outras informações e realiza uma chamada de API
		const codobra = data.codobra;
		const codorcam = data.codorcam;
		const month = NumResults[0].month;
		const year = NumResults[0].year;

		getDataModal(codobra, codorcam, month, year)
			.then(response => {
				this.setState({ Mdata: response });
			})
			.catch(error => {
				console.log(error);
			});
	}

	handleCancel = () => {
		// this.setState({
		// 	data: this.state.originalData,
		// });
		// this.props.history.push('/tabela');
		window.close();
	};

	render() {
		const dataTableConfig = {
			height: '100%',
			reactiveData: true,
		};

		const handleSave = (
			data,
			Mdata,
			props,
			ArrayModal,
			Cid,
			aprovacao_pgto,
			contador,
		) => {
			// cria um objeto com as informações a serem salvas na API
			const saveData = {
				id: Cid,
				aprovacao_pgto: ArrayModal.aprovacao_pgto,
			};

			// configura as opções da requisição
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(saveData),
			};

			// envia a requisição para a API AdonisJS
			fetch(`${REACT_APP_BASE_APP}ctaspagar`, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('Erro ao enviar dados à API');
					}
					return response.json();
				})
				.then(response => {
					if (!response.command) {
						throw new Error(
							'Os dados inseridos estão incorretos. Verifique as informações e tente novamente.',
						);
					}
					console.log('response: ' + JSON.stringify(response));
					console.log(
						'requestOptions: ' + JSON.stringify(requestOptions),
					);
				})
				.catch(error => {
					console.error(error);
					const errorMessage = `${error.message}`;
					// Exibe o alerta personalizado com a mensagem de erro
					// const alert = document.createElement('div');
					// alert.innerText = errorMessage;
					// alert.style.backgroundColor = '#f44336'; // cor vermelha
					// alert.style.color = '#fff'; // cor branca
					// alert.style.padding = '10px';
					// alert.style.borderRadius = '5px';
					// alert.style.position = 'fixed';
					// alert.style.top = '95%';
					// alert.style.left = '75%';
					// alert.style.transform = 'translate(-50%, -50%)';
					// alert.style.zIndex = '9999';

					// // Adiciona a barra de tempo branca na parte inferior do alert
					// const bar = document.createElement('div');
					// bar.style.position = 'absolute';
					// bar.style.bottom = '0';
					// bar.style.width = '100%';
					// bar.style.height = '4px';
					// bar.style.backgroundColor = '#fff';
					// bar.style.opacity = '0.5';
					// alert.appendChild(bar);

					// // Faz a transição suave de entrada do alert
					// alert.style.opacity = '0';
					// alert.style.transition = 'opacity 0.3s ease-in-out';
					// document.body.appendChild(alert);
					// setTimeout(() => (alert.style.opacity = '1'), 10);

					// // Faz a transição suave de saída do alert após 6 segundos
					// setTimeout(() => {
					// 	alert.style.opacity = '0';
					// 	setTimeout(() => document.body.removeChild(alert), 300);
					// }, 4000);
				});
		};

		const handleSave2 = (
			data,
			Mdata,
			props,
			ArrayModal,
			Cid,
			aprovacao_pgto,
			contador,
		) => {
			// cria um objeto com as informações a serem salvas na API
			const saveData = {
				id: Cid,
				aprovacao_pgto: 'true',
			};

			// configura as opções da requisição
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(saveData),
			};

			// envia a requisição para a API AdonisJS
			fetch(`${REACT_APP_BASE_APP}ctaspagar`, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('Erro ao enviar dados à API');
					}
					return response.json();
				})
				.then(response => {
					if (!response.command) {
						throw new Error(
							'Os dados inseridos estão incorretos. Verifique as informações e tente novamente.',
						);
					}
					console.log('response: ' + JSON.stringify(response));
					console.log(
						'requestOptions: ' + JSON.stringify(requestOptions),
					);
				})
				.catch(error => {
					console.error(error);
					const errorMessage = `${error.message}`;
					// Exibe o alerta personalizado com a mensagem de erro
					const alert = document.createElement('div');
					alert.innerText = errorMessage;
					alert.style.backgroundColor = '#f44336'; // cor vermelha
					alert.style.color = '#fff'; // cor branca
					alert.style.padding = '10px';
					alert.style.borderRadius = '5px';
					alert.style.position = 'fixed';
					alert.style.top = '95%';
					alert.style.left = '75%';
					alert.style.transform = 'translate(-50%, -50%)';
					alert.style.zIndex = '9999';

					// Adiciona a barra de tempo branca na parte inferior do alert
					const bar = document.createElement('div');
					bar.style.position = 'absolute';
					bar.style.bottom = '0';
					bar.style.width = '100%';
					bar.style.height = '4px';
					bar.style.backgroundColor = '#fff';
					bar.style.opacity = '0.5';
					alert.appendChild(bar);

					// Faz a transição suave de entrada do alert
					alert.style.opacity = '0';
					alert.style.transition = 'opacity 0.3s ease-in-out';
					document.body.appendChild(alert);
					setTimeout(() => (alert.style.opacity = '1'), 10);

					// Faz a transição suave de saída do alert após 6 segundos
					setTimeout(() => {
						alert.style.opacity = '0';
						setTimeout(() => document.body.removeChild(alert), 300);
					}, 4000);
				});
		};

		const buttonStyle = {
			margin: '5px',
			transition: 'all 0.6s ease-in-out',
			float: 'right',
			marginRight: '20px',
		};

		return (
			<Page
				className="tableAp"
				title="Aprovar Pagamento"
				breadcrumbs={[{ name: 'Tabela', active: true }]}>
				<div
					style={{
						marginTop: '2rem',
						marginBottom: '0.5rem',
					}}>
					<h3>{[this.state.data.nmobra]}</h3>
				</div>
				<div class="parent">
					<div class="div1">
						<div>
							<ReactTabulator
								style={tableStyle}
								rowStyle={rowStyle}
								columns={dataTableColumns}
								data={this.state.Mdata}
								options={dataTableConfig}
								events={{
									tableBuilt: () => {
										const { Mdata } = this.state;
										if (Array.isArray(Mdata)) {
											for (
												let i = 0;
												i < Mdata.length;
												i++
											) {
												const currentElement = Mdata[i];
												const rowData = currentElement;
												const Cid = rowData['id'];
												const valorCell =
													rowData['aprovacao_pgto']; // ou qualquer outra propriedade necessária
												ModalInvite(ArrayModal, {
													id: Cid,
													aprovacao_pgto: valorCell,
												});
											}
										}
									},
									cellEdited: cell => {
										valorCell = cell.getValue();
										const rowData = cell.getRow().getData();
										const Cid = rowData['id'];
										ModalInvite(ArrayModal, {
											id: Cid,
											aprovacao_pgto: valorCell,
										});
									},
								}}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}>
							<Button
								onClick={this.handleCancel}
								variant="outline-danger"
								style={buttonStyle}>
								Cancelar
							</Button>
							<Button
								onClick={() => {
									const { data, Mdata } = this.state;
									for (let i = 0; i < Mdata.length; i++) {
										const currentElement = Mdata[i];
										currentElement.aprovacao = true;
										const contador = i + 1;
										ModalInvite(ArrayModal, {
											id: currentElement.id,
											aprovacao_pgto: true,
										});
									}
								}}
								variant="outline-success"
								style={buttonStyle}>
								Aprovar Todos
							</Button>
							<Button
								onClick={() => {
									for (
										let i = 0;
										i < ArrayModal.length;
										i++
									) {
										console.log(
											'Tentativa de Envio: ' + (i + 1),
										);
										console.log(ArrayModal);
										const currentElement = ArrayModal[i];
										const contador = i + 1;
										handleSave(
											this.state.data,
											this.state.Mdata,
											this.props,
											currentElement,
											currentElement.id,
											currentElement.aprovacao_pgto,
											contador,
										);
									}
									setTimeout(function() {
										window.close();
									}, 1000);
								}}
								variant="outline-success"
								style={buttonStyle}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

export default TableAprove;
