import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const isAuthenticated = () => {
    const user = localStorage.getItem('user');
    return user !== null;
};

console.log('isAuthenticated:', isAuthenticated());

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);

export default PrivateRoute;
