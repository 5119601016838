import { useUser } from 'Context/UserContext';
import * as auth from '../../../api/authApi/auth';
import * as actions from './actions';
import { deleteCookie, getCookie } from 'components/Utils/VerifyCookie';
import { navigate } from '../../../lib/utils/navigation';
import R from '../../../lib/constants/R';

export const useAuth = () => {
  const { user, setUser } = useUser(); 

  const login = async (payload) => {
    try {
      const response = await auth.login({
        ...payload,
        app: process.env.REACT_APP_BASE_APP,
      });

      if (response.status === 200) {
        const user = {
          ativo: response.data.data.ativo,
          created_at: response.data.data.created_at,
          email: response.data.data.email,
          empresa: response.data.data.empresa,
          empresaId: response.data.data.empresaId,
          id: response.data.data.id,
          login: response.data.data.login,
          modulo: response.data.data.modulo,
          moduloId: response.data.data.moduloId,
          nome: response.data.data.nome,
          sistema: response.data.data.sistema,
          usuarioModulo: response.data.data.usuarioModulos,
          updated_at: response.data.data.updated_at,
        };

        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));

        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert(R.strings.error.invalidLogin);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    deleteCookie('token');
    navigate('/login');
  };

  return { login, logout };
};
