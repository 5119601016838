import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Button, NavLink as BSNavLink } from 'reactstrap';
import bn from '../../../lib/utils/bemnames';
import { navItems } from '../../../routes/menu';
import R from '../../../lib/constants/R';
import SidebarPage from './SidebarPages';
import SourceLink from '../SourceLink';
import PropTypes from '../../../lib/utils/propTypes';
import { MdMenuOpen, MdRefresh } from 'react-icons/md';
import { genericsActions } from '../../../store/actions';
import api from '../../../api/appApi/api';
import { useUser } from 'Context/UserContext';
import { connect } from 'react-redux';


const sidebarBackground = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

const Sidebar = ({ modules, permissionsMenu, onSidebar }) => {
    const [isOpenSide, setIsOpenSide] = useState(true);
    const [isUpdating, setIsUpdating] = useState(0);

    const handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();
        const sidebar = document.querySelector('.cr-sidebar');
        const header = document.querySelector('.cr-header');
        sidebar.classList.toggle('cr-sidebar--open');
        header.classList.toggle('open');

        setIsOpenSide(!sidebar.classList.contains('cr-sidebar--open'));
        onSidebar(!sidebar.classList.contains('cr-sidebar--open'));
    };

    const handleClick = name => () => {
        setIsOpenSide(prevState => ({
            ...prevState,
            [`isOpen${name}`]: !prevState[`isOpen${name}`],
        }));
    };

    const modulePermission = (modules) => {
       if(modules.length > 0 ) {
        return modules.map((item, index) => (
            <SidebarPage
                key={item.nome + index}
                isOpen={isOpenSide[`isOpen${item.nome}`]}
                namePages={item.nome}
                click={name => handleClick(name)}
                pageContents={item.submodulos}
                IconPage={item.icone}
            />
        ));
       } else {
        return null
       }
    };

    const updateTableData = async () => {
        if (isUpdating !== 0) {
            return;
        }

        setIsUpdating(1);

        try {
            const response = await api.post('/executar-python');

            switch (response.status) {
                case 200:
                    console.log('Script executado com sucesso');
                    setIsUpdating(2);
                    break;

                case 429:
                    console.log('Script já está sendo executado');
                    setIsUpdating(3);
                    break;

                default:
                    console.log(`Resposta inesperada: ${response.status}`);
                    setIsUpdating(4);
            }
        } catch (error) {
            console.error('Erro ao executar o script:', error);
            handleScriptError();
        }
    };

    const handleScriptError = () => {
        setIsUpdating(3);

        setTimeout(() => {
            setIsUpdating(0);
        }, 10000);
    };

    const { usuario } = useUser();

    return (
        <aside className={bem.b()} data-image={R.images.sidebar}>
            <div className={bem.e('background')} style={sidebarBackground} />

            <div className={bem.e('content')}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 5px', position: 'relative' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <SourceLink>
                            <img src={R.images.logo_oficial} height="70" alt="" />
                        </SourceLink>
                    </div>
                    <div style={{ position: 'absolute', right: '5px' }}>
                        <Button
                            outline
                            onClick={handleSidebarControlButton}
                            className={bem.e('menu')}
                        >
                            <MdMenuOpen size={20} />
                        </Button>
                    </div>
                </div>

                <div>
                    <Nav vertical>
                        {navItems.map(({ to, name, exact, Icon }, index) => (
                            <NavItem key={name} className='custom-nav-item'>
                                <BSNavLink
                                    id={`navItem-${name}-${index}`}
                                    tag={NavLink}
                                    to={to}
                                    activeClassName="active"
                                    exact={exact}
                                >
                                    <Icon className={bem.e('nav-item-icon')} />
                                    <span className="nav-name">{name}</span>
                                </BSNavLink>
                            </NavItem>
                        ))}
                        {usuario?.modulo ? modulePermission(usuario.modulo) : null}

                        <NavItem key='Atualização' className='custom-nav-item'>
                            <Button
                                onClick={updateTableData}
                                className='updateButton'
                            >
                                <MdRefresh style={{ marginRight: '.5rem', color: '#CC9131', fontSize: '1.9rem' }} size={20} />
                                <span className="nav-name">
                                    {isUpdating === 1 ? 'Atualizando...' : isUpdating === 3 ? 'Já em execução' : 'Atualizar'}
                                </span>
                            </Button>
                        </NavItem>
                    </Nav>
                </div>
            </div>
        </aside>
    );
};

const mapStateToProps = state => ({
    modules: state.enterprise.modulesCompany,
    permissionsMenu: state.enterprise.permissionsMenu,
});

const mapDispatchToProps = dispatch => ({
    onSidebar: data => dispatch(genericsActions.sideBar(data)),
});

Sidebar.propTypes = {
    permissionsMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
    modules: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSidebar: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
